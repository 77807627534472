import { gql } from "@apollo/client"

export const GET_SUPPLIER = gql`
  query getSupplier($slug: ID!) {
    supplier(id: $slug, idType: SLUG) {
      id
      title
      slug
      modified
      supplierCategories {
        nodes {
          id
          name
          slug
          parentId
        }
      }
      supplierDetails {
        description
        emailAddress
        introduction
        memberBenefits
        termsAndConditions
        videoUrl
        documents {
          title
          file {
            id
            databaseId
            fileName
            fileSize
            mediaItemUrl
          }
        }
        heroBanner {
          id
          mediaItemUrl
          fileName
          fileSize
          databaseId
        }
        profilePicture {
          id
          mediaItemUrl
          fileName
          fileSize
          databaseId
        }
      }
    }
  }
`

export const UPDATE_SUPPLIER = gql`
  mutation updateSupplier(
    $id: ID!
    $companyName: String
    $email: String
    $description: String
    $introduction: String
    $memberBenefits: String
    $termsAndConditions: String
    $videoUrl: String
    $heroBanner: String
    $profilePicture: String
    $documents: String
    $documentsTitles: String
    $businessCategory: String
    $subBusinessCategory: String
  ) {
    updateSupplier(
      input: {
        id: $id
        title: $companyName
        email: $email
        description: $description
        introduction: $introduction
        memberBenefits: $memberBenefits
        termsAndConditions: $termsAndConditions
        videoUrl: $videoUrl
        heroBanner: $heroBanner
        profilePicture: $profilePicture
        documents: $documents
        documentsTitles: $documentsTitles
        businessCategory: $businessCategory
        subBusinessCategory: $subBusinessCategory
      }
    ) {
      supplier {
        id
        title
        supplierCategories {
          nodes {
            id
            name
            slug
          }
        }
      }
    }
  }
`

export const GET_ALL_SUPPLIERS = gql`
  query getSuppliers {
    suppliers {
      edges {
        node {
          id
          title
          slug
          supplierDetails {
            heroBanner {
              id
              mediaItemUrl
            }
            introduction
            featured
          }
        }
      }
    }
  }
`

export const GET_SUPPLIER_NEWS = gql`
  query GET_SUPPLIER_NEWS {
    supplierNewsItems(where: { status: PUBLISH }, first: 10000) {
      edges {
        node {
          id
          slug
          modified
          title(format: RENDERED)
          featuredImage {
            node {
              mediaItemUrl
              fileName
              altText
            }
          }
          newsPost {
            inReview
            approvalStatus
            linkedSupplier {
              ... on Supplier {
                id
                title
                supplierDetails {
                  profilePicture {
                    mediaItemUrl
                    fileName
                  }
                }
              }
            }
            postContent
            publishedStatus
            videoUrl
            documents {
              title
              file {
                mediaItemUrl
                fileName
              }
            }
            expiryDate
          }
        }
      }
    }
  }
`

export const GET_SUPPLIER_NEWS_ITEM = gql`
  query getSupplierNewsItem($slug: ID!) {
    supplierNews(id: $slug, idType: SLUG) {
      id
      slug
      title(format: RENDERED)
      modified
      databaseId
      featuredImage {
        node {
          id
          fileName
          fileSize
          databaseId
          mediaItemUrl
        }
      }
      newsPost {
        inReview
        approvalStatus
        linkedSupplier {
          ... on Supplier {
            id
            title
            supplierDetails {
              profilePicture {
                mediaItemUrl
                fileName
              }
            }
          }
        }
        postContent
        publishedStatus
        videoUrl
        documents {
          title
          file {
            mediaItemUrl
            fileName
            fileSize
            databaseId
          }
        }
        expiryDate
      }
    }
  }
`

export const REFRESH_AUTH_TOKEN = gql`
  mutation refreshAuthToken($refreshToken: String!) {
    refreshJwtAuthToken(input: { jwtRefreshToken: $refreshToken }) {
      authToken
    }
  }
`
